// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Material+Icons);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Material+Icons+Outlined);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);"]);
// Module
exports.push([module.id, "table,.app,tr{display:flex}table,.app{flex-flow:column nowrap}tr{flex-flow:row nowrap}thead,tbody td{font-size:.875rem;line-height:1.3125}thead{font-weight:600}table{width:100%;border-collapse:collapse}thead{flex:0 0 auto;background-color:#000;color:rgba(255,255,255,0.5);text-transform:uppercase}tr:nth-of-type(odd){background-color:rgba(0,0,0,0.1)}td{align-items:flex-start;flex:1 1 auto;padding:.75rem 1.5rem}tbody{flex:1 1 auto}.bg-gray-100{background-color:#bebebe !important}.bg-gray-200{background-color:#a2a2a2 !important}.bg-gray-300{background-color:#898989 !important}.bg-gray-400{background-color:#727272 !important}.bg-gray-500{background-color:#5d5d5d !important}.bg-gray-600{background-color:#484848 !important}.bg-gray-700{background-color:#333 !important}.bg-gray-800{background-color:#2b2b2b !important}.bg-gray-900{background-color:#222 !important}body{font-family:-apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;margin:0;padding:0;width:100vw;height:100vh;background-color:white;overflow:hidden;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;-webkit-tap-highlight-color:rgba(255,255,255,0)}html,body{font-size:16px}#root{width:100vw;height:100vh;background-color:#131313}.app{width:100vw;height:100vh}.app__content{flex:1 1 auto;overflow-y:auto}@media (max-width: 768px){html,body{font-size:15px}.app__content{width:100vw;overflow-x:hidden}}\n", ""]);
// Exports
module.exports = exports;
